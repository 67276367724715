<template>
  <div v-if="profile">
    <div class="bg-section">
      <v-img
        src="@/assets/user/bg_user_profile.png"
        lazy-src="@/assets/user/bg_user_profile_small.jpg"
        height="300px"
      />
      <div class="profile-section">
        <v-img
          :src="profile.image ? profile.image : placeholderImage"
          width="100%"
          height="100%"
        />
      </div>
    </div>
    <div class="info-section">
      <div class="info-section-name">
        <div>
          {{
            profile.first_name +
              (profile.last_name ? " " + profile.last_name : "")
          }}
        </div>
        <v-btn
          icon
          class="ml-5"
          @click="onEditProfile"
          v-if="profileType == 'User'"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </div>
      <div class="info-section-desc">
        {{ profile.email }}
      </div>
      <div class="info-section-desc">
        {{ profile.phone }}
      </div>
    </div>
    <div class="card-section">
      <div
        style="font-family: 'Poppins-SemiBold'; font-size: 20px; color: #423f63;"
      >
        My Card
      </div>
      <div
        style="font-family: 'Poppins-Medium'; color: #423f63; margin-top: 10px;"
      >
        Your id number is the number registered in your identification, with
        this you can register with any event and be part of it.
      </div>
      <div style="margin-top: 30px;">
        <v-img src="@/assets/user/img_card.png" class="card-section-image">
          <div style="position: absolute; bottom: 20px; left: 20px;">
            <div style="color: #423f63; font-family: 'Poppins-Medium';">
              ID NUMBER
            </div>
            <div
              style="color: #423f63; font-family: 'Poppins-Bold'; font-size: 20px"
            >
              {{ profile.user_id }}
            </div>
          </div>
        </v-img>
      </div>
    </div>
    <div class="interests-section">
      <div
        style="font-family: 'Poppins-SemiBold'; font-size: 20px; color: #423f63;"
      >
        My Interests
        <v-btn
          icon
          class="ml-5"
          @click="onEditInterests"
          v-if="profileType == 'User'"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
      </div>
      <div
        style="font-family: 'Poppins-Medium'; color: #423f63; margin-top: 10px;"
      >
        <v-chip-group column>
          <v-chip v-for="(interest, index) in profile.interests" :key="index">
            {{ interest }}
          </v-chip>
        </v-chip-group>
      </div>
    </div>
    <edit-user-profile-dialog
      :dialog="editDialog"
      :onClose="onCloseDialog"
      v-if="editDialog && profileType == 'User'"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import EditUserProfileDialog from "./EditUserProfileDialog.vue";
export default {
  components: { EditUserProfileDialog },
  data() {
    return {
      placeholderImage: require("@/assets/default.jpg"),
      editDialog: false,
    };
  },
  methods: {
    onEditProfile() {
      this.editDialog = true;
    },
    onCloseDialog() {
      this.editDialog = false;
    },
    onEditInterests() {
      this.$router.push({ name: "select-interests" });
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
};
</script>
<style scoped>
.bg-section {
  position: relative;
}
.profile-section {
  width: 305px;
  height: 273px;
  margin-left: 160px;
  bottom: -55px;
  position: absolute;
  padding: 10px;
  background-color: white;
  max-width: 50%;
}
.info-section {
  margin-left: 160px;
  margin-top: 60px;
}
.info-section-name {
  font-family: "Poppins-SemiBold";
  font-size: 30px;
  color: #423f63;
  display: flex;
  align-items: center;
}
.info-section-desc {
  font-family: "Poppins-Medium";
  color: #423f63;
}
.card-section {
  margin-left: 160px;
  margin-right: 50px;
  margin-top: 60px;
}
.card-section-image {
  width: 406px;
  height: 206px;
  position: relative;
}
.interests-section {
  margin-left: 160px;
  margin-top: 60px;
  margin-right: 50px;
  padding-bottom: 60px;
}
@media only screen and (max-width: 600px) {
  .profile-section {
    width: 200px;
    height: 180px;
    bottom: -55px;
    padding: 10px;
    margin: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .info-section {
    margin-top: 60px;
    margin-left: 0px;
  }
  .info-section-name {
    justify-content: center;
  }
  .info-section-desc {
    text-align: center;
  }
  .card-section {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 50px;
    padding-right: 50px;
  }
  .card-section-image {
    width: 284.2px;
    height: 144.2px;
    position: relative;
  }
  .interests-section {
    margin-left: 50px;
    margin-top: 60px;
    margin-right: 50px;
    padding-bottom: 60px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .profile-section {
    width: 305px;
    height: 273px;
    margin-left: 160px;
    bottom: -55px;
    padding: 10px;
  }
  .card-section {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .profile-section {
    width: 305px;
    height: 273px;
    margin-left: 160px;
    bottom: -55px;
    padding: 10px;
  }
}
@media only screen and (min-width: 992px) {
  .profile-section {
    width: 305px;
    height: 273px;
    margin-left: 160px;
    bottom: -55px;
    padding: 10px;
  }
  .card-section {
    margin-left: 160px;
    margin-right: 50px;
    margin-top: 60px;
  }
  .interests-section {
    margin-left: 160px;
    margin-top: 60px;
    margin-right: 50px;
    padding-bottom: 60px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .profile-section {
    width: 305px;
    height: 273px;
    margin-left: 160px;
    bottom: -55px;
    padding: 10px;
  }
}
</style>
