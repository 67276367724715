<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Edit your profile image and name.
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column"
        style="font-family:'Poppins-Medium';color: #1e1e1e"
      >
        Select Profile
        <div class="d-flex justify-center">
          <editable-avatar
            :image="profile.image"
            :onImageSelected="onImageSelected"
          />
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <v-row class="mt-2">
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              First Name
            </div>
            <v-text-field
              label="First Name"
              solo
              flat
              outlined
              dense
              v-model="profile.first_name"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Last Name
            </div>
            <v-text-field
              label="Last Name"
              solo
              flat
              outlined
              dense
              v-model="profile.last_name"
              style="font-family:'Poppins-Regular';"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Email
            </div>
            <v-text-field
              label="Email"
              solo
              flat
              outlined
              dense
              v-model="profile.email"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Phone Number
            </div>
            <v-text-field
              label="Phone"
              solo
              flat
              outlined
              dense
              v-model="profile.phone"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="6" class="py-0">
            <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
              Zip
            </div>
            <v-text-field
              label="Zip"
              solo
              flat
              outlined
              dense
              v-model="profile.zip"
              style="font-family:'Poppins-Regular';"
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="primary"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
import EditableAvatar from "@/components/core/EditableAvatar.vue";
export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      code: "",
      name: "",
      message: null,
      loading: false,
      sendingCode: false,
      disableResend: false,
      resendCount: 0,
      rules: {
        required: (value) => !!value || "Required.",
      },
      selectedImage: null,
      profile: {},
    };
  },
  methods: {
    ...mapActions("user", {
      updateProfile: "editUser",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      // if (!this.profile.image && !this.selectedImage) {
      //   this.message = "Please select your profile image.";
      //   return;
      // }
      if (!this.profile.first_name) {
        this.message = "Please add your first name.";
        return;
      }
      if (!this.profile.email) {
        this.message = "Please add your email.";
        return;
      }
      this.message = null;
      var formData = new FormData();
      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      formData.append("first_name", this.profile.first_name);
      if (this.profile.last_name) {
        formData.append("last_name", this.profile.last_name);
      }
      if (this.profile.email) {
        formData.append("email", this.profile.email);
      }
      if (this.profile.phone) {
        formData.append("phone", this.profile.phone);
      }
      if (this.profile.zip) {
        formData.append("zip", this.profile.zip);
      }
      this.loading = true;
      this.updateProfile(formData)
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
      this.message = null;
    },
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile",
    }),
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    },
  },
  mounted() {
    this.profile = { ...this.myProfile };
  },
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.text-transform-none {
  text-transform: none;
}
</style>
